import { useCallback, useEffect, useState } from "react"
import { auth, db } from "../../utils/firebase"
import {
  signInWithEmailAndPassword, onAuthStateChanged,
  createUserWithEmailAndPassword,
  deleteUser
} from "firebase/auth";
import { addDoc, collection } from "firebase/firestore";
import { toast } from "react-toastify";

export default function AffiliateSteps() {


  const [user, setUser] = useState(null)

  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        setUser(user)
        document.querySelector("#join-now").scrollIntoView({ behavior: "smooth", block: "center" })
      } else {
        setUser(null)
      }
    })
    setUser(auth.currentUser)
  }, [])

  const getUserStripeId = useCallback(() => {
    const jsonCustomAttributes = JSON.parse(user?.reloadUserInfo?.customAttributes || "{}")
    return jsonCustomAttributes?.stripe_connect_account_id
  }, [user])

  useEffect(() => {
    if (!user) return setCurrentStep(0)

    const jsonCustomAttributes = JSON.parse(user?.reloadUserInfo?.customAttributes || "{}")

    if (jsonCustomAttributes.charges_enabled) {
      return setCurrentStep(3)
    } else {
      return setCurrentStep(2)
    }
  }, [user])

  const [currentStep, setCurrentStep] = useState(0)

  return <section class="bg-gray-50" id="join-now">
    <div class="max-w-screen-xl px-4 py-8 mx-auto space-y-12 lg:space-y-20 lg:py-24 lg:px-6">
      {
        currentStep === 0 && <form
          onSubmit={(e) => {
            e.preventDefault()
            signInWithEmailAndPassword(auth, e.target.email.value, e.target.password.value)
          }}
          class="flex flex-col max-w-lg p-6 mx-auto text-center text-gray-900 bg-white border border-gray-100 rounded-lg shadow">
          <div class="flex flex-col px-5 py-3">
            <div class="w-full flex items-center justify-between">
              <h3 class="mb-4 text-3xl font-semibold">Login</h3>
              <p class="text-sm cursor-pointer text-1xl lg:hover:text-purple-700 text-gray-500"
                onClick={() => {
                  setCurrentStep(1)
                }}
              >Don't Have an Account?
                Sign Up
              </p>
            </div>

            <div class="flex flex-col items-start mt-3">
              <label for="email" class="block text-[250] font-[500] text-gray-500  mb-0 p-0">Email
                Address</label>
              <input type="email" id="email" name="email"
                class="block w-full px-4 py-2 mt-2 text-sm text-gray-700 placeholder-gray-400 bg-white border border-gray-300 rounded-lg focus:ring-purple-900 focus:border-purple-900 focus:ring-2"
                placeholder="Enter your email" required />
            </div>

            <div class="flex flex-col items-start mt-3 mb-8">
              <label for="email" class="block text-[250] font-[500] text-gray-500  mb-0 p-0">Password</label>
              <input type="password" id="password" name="password"
                class="block w-full px-4 py-2 mt-2 text-sm text-gray-700 placeholder-gray-400 bg-white border border-gray-300 rounded-lg focus:ring-purple-900 focus:border-purple-900 focus:ring-2"
                placeholder="Enter your password" required />
            </div>

            <button
              class="text-white bg-purple-600 hover:bg-purple-700 focus:ring-4 focus:ring-purple-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center ">Login</button>
          </div>
        </form>
      }
      {
        currentStep === 1 && <form
          onSubmit={async (e) => {
            e.preventDefault()
            const email = e.target.email.value
            const phone = e.target.phone.value
            const name = e.target.name.value
            const password = e.target.password.value

            await createUserWithEmailAndPassword(auth, email, password)
              .then(async (userCredential) => {
                try {
                  await addDoc(collection(db, "UsersPersonalInfo"), {
                    email: email,
                    phone: phone,
                    name: name,
                    id: userCredential.user.uid,
                  });
                } catch (e) {
                  console.log("Error:", e);
                  alert("Error creating user")
                  await deleteUser(userCredential.user);
                }
              })
              .catch((error) => {
                console.log("Error:", error);
                console.log("code", error.code);
                if (error.code === "auth/email-already-in-use") {
                  alert(
                    "Email already exists.\n Try logging in or changing password if you forgot password."
                  );
                }
              });
          }}
          class="flex flex-col max-w-lg p-6 mx-auto text-center text-gray-900 bg-white border border-gray-100 rounded-lg shadow "
        >
          <div class="flex flex-col px-5 py-3">
            <div class="w-full flex items-center justify-between">
              <h3 class="mb-4 text-3xl font-semibold">Register</h3>
              <p class="text-sm cursor-pointer text-1xl text-gray-500 lg:hover:text-purple-700" onClick={() => {
                setCurrentStep(0)
              }}>
                Already Have an Account? Login
              </p>
            </div>

            <div class="flex flex-col items-start mt-4">
              <label for="email" class="block text-[250] font-[500] text-gray-500  mb-0 p-0">Name</label>
              <input type="text" id="name" name="name"
                class="block w-full px-4 py-2 mt-2 text-sm text-gray-700 placeholder-gray-400 bg-white border border-gray-300 rounded-lg focus:ring-purple-900 focus:border-purple-900 focus:ring-2"
                placeholder="Enter your name" required />
            </div>


            <div class="flex flex-col items-start mt-4">
              <label for="email" class="block text-[250] font-[500] text-gray-500  mb-0 p-0">Email
                Address</label>
              <input type="email" id="email" name="email"
                class="block w-full px-4 py-2 mt-2 text-sm text-gray-700 placeholder-gray-400 bg-white border border-gray-300 rounded-lg focus:ring-purple-900 focus:border-purple-900 focus:ring-2"
                placeholder="Enter your email" required />
            </div>

            <div class="flex flex-col items-start mt-4">
              <label for="email" class="block text-[250] font-[500] text-gray-500  mb-0 p-0">Password</label>
              <input type="password" id="password" name="password"
                class="block w-full px-4 py-2 mt-2 text-sm text-gray-700 placeholder-gray-400 bg-white border border-gray-300 rounded-lg focus:ring-purple-900 focus:border-purple-900 focus:ring-2"
                placeholder="Enter your password" required />
            </div>

            <div class="flex flex-col items-start mt-4 mb-8">
              <label for="email" class="block text-[250] font-[500] text-gray-500  mb-0 p-0">Phone</label>
              <input type="text" id="phone" name="phone"
                class="block w-full px-4 py-2 mt-2 text-sm text-gray-700 placeholder-gray-400 bg-white border border-gray-300 rounded-lg focus:ring-purple-900 focus:border-purple-900 focus:ring-2"
                placeholder="Enter your password" required />
            </div>

            <button
              class="text-white bg-purple-600 hover:bg-purple-700 focus:ring-4 focus:ring-purple-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Register</button>
          </div>
        </form>
      }

      {
        currentStep === 2 && <div
          class="flex flex-col max-w-2xl p-6 relative mx-auto text-center text-gray-900 bg-white border border-gray-100 rounded-lg shadow xl:p-2 ">
          <div class="flex flex-col px-5 py-3">
            <div>

              <h3 class="mb-2 text-2xl font-bold ">
                Just one step away from earning money
              </h3>
              <p class="font-light text-gray-500   font-[600]">
                Create your affiliate stripe account to start earning money.
              </p>
            </div>
            <button
              onClick={async () => {
                const res = await fetch(`${process.env.REACT_APP_API_URL}/create-connect-account`, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify({
                    id_token: await user.getIdToken()
                  })
                })

                const data = await res.json()

                if (data.url) {
                  window.location.href = data.url || "/"
                }
                else {
                  alert("Error creating account")
                }

                return false
              }}
              class="text-white bg-purple-600 hover:bg-purple-700 focus:ring-4 focus:ring-purple-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center mt-5">
              Create your affiliate stripe account
            </button>

          </div>
          <span className="absolute right-[10px] bottom-[-35px] text-gray-500 cursor-pointer" onClick={() => {
            auth.signOut()
          }}>
            logout
          </span>
        </div>
      }

      {
        currentStep === 3 && <div
          class="flex flex-col max-w-2xl p-6 mx-auto text-center text-gray-900 bg-white border border-gray-100 rounded-lg shadow xl:p-2 ">
          <div class="flex flex-col px-5 py-3">
            <div>

              <h3 class="mb-2 text-2xl font-bold ">
                Everything ready to start earning money
              </h3>
              <p class="font-light text-gray-500   font-[600]">
                Share your affiliate link and earn money for each user who signs up for a paid plan with your link.
              </p>
            </div>


            <div class="flex flex-col items-start mt-3">
              <label for="email" class="block text-[250] font-[500] text-gray-500  mb-0 p-0">Affiliate
                Link</label>
              <input type="email" id="email" name="email"
                class="block w-full px-4 py-2 mt-2 text-sm text-gray-700 placeholder-gray-400 bg-white border border-gray-300 rounded-lg focus:ring-purple-900 focus:border-purple-900 focus:ring-2"
                readonly="readonly" value={`https://grantaide.com/id=${getUserStripeId()}`} required />
            </div>

            <button href="#"

              onClick={(e) => {
                e.preventDefault()
                navigator.clipboard.writeText(`https://grantaide.com/?aff_id=${getUserStripeId()}`)
                toast.success("Link copied to clipboard")
              }}
              class="text-white bg-purple-600 hover:bg-purple-700 focus:ring-4 focus:ring-purple-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center mt-5">Copy
              Link</button>

          </div>

        </div>
      }






    </div>
  </section >
}