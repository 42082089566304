import React, { useEffect, useState } from "react";
import { auth, db } from "../../utils/firebase";
import { getDocs, collection } from "firebase/firestore";
import { Link, useNavigate } from "react-router-dom";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import "./dashboard.css";
import { signOut } from "firebase/auth";
import ProposalCard from "../../Components/ProposalCard"; 
import { styled } from "@mui/material/styles";
import {Button, Modal, Box, Typography, TextField } from "@mui/material"; 


function Dash2() {
  const [data, setData] = useState([]);
  const [startExistingProfile, setStartExistingProfile] = useState(true); const [open, setOpen] = useState(false);
  const [clients, setClients] = useState([]);
  const [clientData, setClientData] = useState({
    name: "",
    organizationName: "",
    email: "",
    phone: "",
    website: ""
  });

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();

  //MUI styles
  const customStyle = {
    backgroundColor: "#18003F",
    color: "white",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#1B4595",
      },
      "&:hover fieldset": {
        borderColor: "#1B4595",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#1B4595",
      },
    },
    "& .MuiInputLabel-root": {
      color: "white",
    },
    "& .MuiInputBase-input": {
      color: "white",
    },
  };

  const AddClientButton = styled(Button)({
    height: "45px",
    color: "white",
    width: "97%",
    fontSize: "18px",
    backgroundColor: "#380093",
    border: "solid 3px #1B4595",
    margin: "5px 0",
  });

  const ViewAllClientButton = styled(Button)({
    backgroundColor: "#784DBD",
    border: "2px solid #DBC5FF",
    width: "97%",
    height: "45px",
    color: "white",
  });

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "black",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setClientData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCreateClient = () => {
    console.log("Client Details:", clientData);
    setClients([...clients, clientData]);
    setClientData({
      name: "",
      organizationName: "",
      email: "",
      phone: "",
      website: ""
    });
    handleClose();
  };

  //checking only loggedin user to use it
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (!user) {
        navigate("/login");
      }
    });
    return () => unsubscribe();
  }, []);

  const logouthandle = async (e) => {
    e.preventDefault();
    signOut(auth);
    navigate("/");
  };

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        const uid = user.uid;
        readData(uid);
      }
    });

    if (auth.currentUser) {
      readData(auth.currentUser.uid);
    }
  }, []);

  async function readData(uid) {
    try {
      const docRef = collection(db, "Data", "Forms", uid);
      const docSnapshot = await getDocs(docRef);
      const userData = [];
      docSnapshot.forEach((doc) => {
        const dataWithid = {
          id: doc.id,
          ...doc.data(),
        };
        userData.push(dataWithid);
      });
      setData(userData);
      console.log();
    } catch (error) {
      console.error("Error reading document:", error);
    }
  }

  const handleNewProfile = () => {
    localStorage.removeItem("oldprofile"); // Remove the "oldprofile" key from localStorage
    navigate("/form");
  };

  const handleClick = (profile_id) => {
    if (startExistingProfile) {
      localStorage.setItem("oldprofile", profile_id);
      const prof = localStorage.getItem("oldprofile");
      if (prof === profile_id) {
        console.log("Data Updated");
        navigate("/form");
      } else {
        console.log("error updating the data");
      }
    } else {
      alert(
        "Select `Start with an existing profile` if you wish to move with it."
      );
    }
  };

  return (
    <div>
      <div className="bg-gradient-to-l from-green-400 from-12.76% via-sky-500 from-35.58% via-purple-500 from-63.06%  to-fuchsia-500 to-89.1% ... w-full h-12 flex justify-center align-middle">
        <h2 className="pl-10 py-2 text-3xl text-white font-bold ">GrantAIde</h2>
        <div className="header-btns-div text-black">
          <div className="header-btns-left">
            <Link to="/proposal-dashboard">
              <input
                type="submit"
                className="header-btns"
                name="profile-btn"
                value="Dashboard"
              />
            </Link>
          </div>
          <div className="header-btns-right">
            <input
              type="submit"
              className="header-btns"
              name="sign out"
              value="Sign Out"
              onClick={logouthandle}
            />
          </div>
        </div>
      </div>
      <div className="dash-2 top-12">
        <div className="w-1/5 bg-[#11002e] border-r-4 border-[#5406D2] overflow-auto flex">
          <div className="w-1/6 bg-[#250062] flex">
            {/* <Fab size="small"></Fab> */}
          </div>
          {/* <NewClientSidebar /> */}
          <div className="left-client-sidemenu w-1/5 content-start">
            <Typography
              sx={{ display: "flex", justifyContent: "left" }}
              id="modal-title"
              color="white"
              className="pl-3 pt-8 pb-8 text-xs"
            >
              Client List
            </Typography>
            <AddClientButton
              type="button"
              className="client-add-btn"
              onClick={handleOpen}
            >
              <AddCircleOutlineIcon />
              <div className="text-sm border-indigo-900 pl-1">Create New Client</div>
            </AddClientButton>
            {/* <Link to="/all-client-dashboard">
              <ViewAllClientButton
                type="button"
                onClick={handleOpen}
              >
                <div>View All Clients</div>
              </ViewAllClientButton>
            </Link> */}

            <div className="client-list">
              {clients.map((client, index) => (
                <div key={index} className="client-name-div">
                  {client.name}
                </div>
              ))}
            </div>

            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-title"
              aria-describedby="modal-description"
            >
              <Box sx={modalStyle}>
                <Typography
                  sx={{ display: "flex", justifyContent: "center" }}
                  id="modal-title"
                  variant="h6"
                  component="h2"
                  color="white"
                >
                  New Client Details
                </Typography>
                <TextField
                  id="name"
                  label="Name"
                  name="name"
                  value={clientData.name}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  required
                  sx={{ mt: 2, ...customStyle }}
                />
                <TextField
                  id="organization-name"
                  label="Organization Name"
                  name="organizationName"
                  value={clientData.organizationName}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  required
                  sx={{ mt: 2, ...customStyle }}
                />
                <TextField
                  id="email"
                  label="Email Address"
                  name="email"
                  value={clientData.email}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  required
                  sx={{ mt: 2, ...customStyle }}
                />
                <TextField
                  id="phone"
                  label="Phone Number"
                  name="phone"
                  value={clientData.phone}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  sx={{ mt: 2, ...customStyle }}
                />
                <TextField
                  id="website"
                  label="Website"
                  name="website"
                  value={clientData.website}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  sx={{ mt: 2, ...customStyle }}
                />
                <div className="file-upload-btns">
                  <Button
                    onClick={handleCreateClient}
                    variant="contained"
                    color="primary"
                    sx={{ mt: 2 }}
                  >
                    Create
                  </Button>
                  <Button
                    onClick={handleClose}
                    variant="contained"
                    color="primary"
                    sx={{ mt: 2 }}
                  >
                    Cancel
                  </Button>
                </div>
              </Box>
            </Modal>
          </div>
        </div>
        <div className="w-4/5 pl-3">
          {/* <div className="create-container"> */}
          {/* <div className="create" onClick={handleNewProfile}>
              <AddCircleOutlineIcon />
              Create a new Profile
            </div> */}
          {/* <input type="text" className="search-bar text-white" placeholder="Search" /> */}
          {/* <div className="info-text"> This will create a new profile</div> */}
          {/* </div> */}
          <div className="flex items-center justify-between mb-4 mt-3">
            <button className="button2">
              <AddCircleOutlineIcon />
              <div className="pl-1" onClick={handleNewProfile}>Create new Proposal</div>
            </button>
            {/* <input type="text" className="search-bar text-white" placeholder="Search" /> */}
          </div>
          <div className="create-container">
            <div
              className="existing"
              onClick={() => setStartExistingProfile(true)}
            >
              {" "}
              Start with an existing profile from below
            </div>
            {/* <div className="info-text">
              {" "}
              When using an existing profile from below make sure to edit the
              necessary fields in the form e.g. Due Date, Due Time. etc.
            </div> */}
          </div>

          <div className="flex w-full mt-1">
            <div className="dash p-1 d-flex items-center gap-3 bg-red-400 w-fit">
              {data.map((item, index) => (
                <ProposalCard
                  key={index}
                  item={item}
                  index={index}
                  handleClick={handleClick}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

// { message } needs to be passed to "ChatMessage", holding for testing

export default Dash2;
