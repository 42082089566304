import React, { useState, useEffect } from "react";
import "./component.css";

const TableOfContent = ({ uid, userQuestions }) => {
  const [messageList, setMessageList] = useState([]);
  const [currentQuestion,setCurrentQuestion] = useState(0);
  useEffect(() => {
    if (Array.isArray(userQuestions)) {
      setMessageList(userQuestions.map((question) => ({
        ...question,
        message: question.message.trim(), // Remove leading/trailing whitespace
      })));
    } else {
      console.error("userQuestions is not an array", userQuestions);
    }
  }, [userQuestions]);
  return (
    <>
      <div className="tableofcontent-container h-4/5 w-full overscroll-contain scroll-smooth custom-scrollbar">
        <ul className="table-of-content">
          {messageList.map((question, index) => (
            <li key={index} className="question-item">
              <span className="question-number">{index + 1}:</span>
              <span className="question-text">
                <a href={`#tab-${index}`} onClick={()=>{
                  setCurrentQuestion(index)
                  console.log(index)
                  }} className={`${currentQuestion===index?'underline':'no-underline'}`}> {question.message}</a>
                </span>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default TableOfContent;
