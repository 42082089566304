import { React, useEffect, useState, useRef } from "react";
import classNames from "classnames";
import { marked } from "marked";
import { Link, useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import { auth } from "../utils/firebase";
import FileUploader from "../Components/FileUploader";
import LinearProgress from "@mui/material/LinearProgress";
import WrapperPlan from "../Components/WrapperPlan";
import ChatInput from "../Components/ChatInput";
import TableOfContent from "../Components/TableOfContent";
import Rpfquestions from "../Components/Rpfquestions";
import ChatMessage from "../Components/ChatMessage";
import { Typography } from "@mui/material";
//For PDF conversion
import { PDFDownloadLink, Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
const baseurl = "https://app.grantaide.com/";

function Main() {
  const [showPdfLink, setShowPdfLink] = useState(false);
  const [sortedData, setSortedData] = useState();
  const [input, setInput] = useState("");
  const [messageList, setMessageList] = useState([]);
  const [userQuestions, setUserQuestions] = useState([]);
  const [waitingForResponse, setWaitingForResponse] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [sentformData, setSentformData] = useState(false);
  const [displayMode, setDisplayMode] = useState("words");
  const [wordCount, setWordCount] = useState(0);
  const [gptVersion, setGptVersion] = useState("gpt-4o-mini");
  const [charCount, setCharCount] = useState(0);
  const [activeTab, setActiveTab] = useState("tableOfContent");
  const messagesEndRef = useRef(null);
  const navigate = useNavigate();
  const user = auth.currentUser;

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (!user) {
        navigate("/login");
      }
    });
    populateChat();
    return () => unsubscribe();
  }, []);

  const uid = user ? user.uid : null;
  const profileName = localStorage.getItem("profile");

  function clearChat() {
    setMessageList([]);
  }

  const logouthandle = async (e) => {
    e.preventDefault();
    signOut(auth);
    navigate("/");
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const filteredAnswers = (userQuestions) => {
    if (!Array.isArray(userQuestions)) {
      return [];
    }

    return userQuestions.filter((question) => question.user === "user");
  };
  const convertToPdf = (data) => {
    let counter = 0;

    const content = data.map((entry) => {
      if (entry.user === 'user') {
        counter++;
        return `${counter}) ${entry.user}: ${entry.message}`;
      }
      return `${entry.user}: ${entry.message}`;
    });

    const styles = StyleSheet.create({
      page: {
        flexDirection: 'row',
        backgroundColor: '#E4E4E4'
      },
      section: {
        margin: 10,
        padding: 10,
        flexGrow: 1
      },
      text: {
        fontSize: 12,
        margin: 10
      }
    });
    setShowPdfLink(true);
    return (
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.section}>
            {content.map((text, index) => (
              <Text key={index} style={styles.text}>
                {text}
              </Text>
            ))}
          </View>
        </Page>
      </Document>
    );
  };


  useEffect(() => {
    scrollToBottom();
  }, [messageList]);

  async function populateChat() {
    const response = await fetch(`${baseurl}chat-history`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_id: uid,
        document_id: profileName,
      }),
    });
    try {
      const data = await response.json();
      const transformData = (data) => {
        const transformed = [];

        data.map((item) => {
          if (item.question) {
            transformed.push({ user: "user", message: item.question });
          }
          if (item.answer) {
            transformed.push({ user: "bot", message: item.answer });
          }
        });
        setSortedData(convertToPdf(transformed));
        return transformed;
      };
      const transformedData = transformData(data);
      // const lastTenTexts = transformedData.slice(
      //   Math.max(transformedData.length - 10, 0)
      // );


      setMessageList((prevMessageList) => {
        const newMessages = transformedData.filter(
          (newMsg) =>
            !prevMessageList.some(
              (prevMsg) =>
                prevMsg.user === newMsg.user &&
                prevMsg.message === newMsg.message
            )
        );
        return [...prevMessageList, ...newMessages];
      });

      const updatedUserQuestions = messageList
        .filter((msg) => msg.user === "user")
        .map((msg) => ({ ...msg, uid }));
      setUserQuestions(...updatedUserQuestions);

      setTimeout(() => {
        scrollToBottom();
      }, 1000);
    } catch (error) {
      console.log("error: " + error.message);
    }
  }

  async function sendformData() {
    setWaitingForResponse(true);
    setIsLoading(true);
    const res = fetch(`${baseurl}gptRun/form`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user: uid,
        profile: profileName,
      }),
    });
    setWaitingForResponse(false);
    setIsLoading(false);
  }

  useEffect(() => {
    const updatedUserQuestions = messageList
      .filter((msg) => msg.user === "user")
      .map((msg) => ({ ...msg, uid }));
    setUserQuestions(...updatedUserQuestions);
  }, [messageList]);

  async function handleSubmit(e) {
    // e.preventDefault();
    if (!waitingForResponse && input) {
      setWaitingForResponse(true);
      setIsLoading(true);
      let newMessageList = [
        ...messageList,
        { user: "user", message: `${input}` },
      ];
      setInput("");
      setMessageList(newMessageList);

      const response = await fetch(`${baseurl}gptRun/aiapp`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          query: input,
          uid: uid,
          profile: profileName,
          gptVersion: gptVersion,
        }),
      });
      try {
        const data = await response.json();
        setMessageList([
          ...newMessageList,
          { user: "bot", message: `${data.message}` },
        ]);
        setWaitingForResponse(false);
        setIsLoading(false);

        newMessageList.forEach((msg, index) => {
          if (msg.user === "user") {
            setUserQuestions({ ...msg, uid: uid, index });


          }
        });
      } catch (error) {
        console.log("error: " + error.message);
      }
    }
  }

  if (!sentformData) {
    sendformData();
    setSentformData(true);
  }

  function handleRefreshClick() {
    console.log("Clicked outer refresh");
  }

  const handleDisplayModeChange = (mode) => {
    setDisplayMode(mode);
  };

  const handleGptVersionChange = (version) => {
    setGptVersion(version);
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <WrapperPlan autoOpen={true}>

      {/* <div> */}
      <div className="bg-gradient-to-l from-green-400 from-12.76% via-sky-500 from-35.58% via-purple-500 from-63.06%  to-fuchsia-500 to-89.1% ... w-full h-12 flex justify-center align-middle">
        <h2 className="pl-10 py-2 text-3xl text-white font-bold ">
          GrantAIde
        </h2>
        <div className="header-btns-div text-black">
          <div className="header-btns-left">
            <Link to="/proposal-dashboard">
              <input
                type="submit"
                className="header-btns"
                name="profile-btn"
                value="Dashboard"
              />
            </Link>
          </div>
          <div className="header-btns-right">
            <input
              type="submit"
              className="header-btns"
              name="sign out"
              value="Sign Out"
              onClick={logouthandle}
            />
          </div>
        </div>
      </div>
      <div className="dash-2 top-12">
        <div className="w-1/5 bg-[#11002e] border-[#5406D2]">
          <div className="text-center align-middle justify-center mt-5 text-xl font-semibold">
            <h1>{profileName}</h1>
          </div>
          <div className="flex flex-col text-sm text-nowrap justify-center lg:flex-row">
            <button
              className={`mt-4 py-2 w-full duration-200 break-words break-normal border-b-8 border-[#400080] ${activeTab === "tableOfContent" ? "bg-[#400080]" : "bg-[#2C0075]"
                } rounded-t-lg`}
              onClick={() => handleTabChange("tableOfContent")}
            >
              Table of Content
            </button>
            <button
              className={`mt-4 py-2 w-full duration-200 break-words break-normal border-b-8 border-[#400080] ${activeTab === "rpfQuestions" ? "bg-[#400080]" : "bg-[#2C0075]"
                } rounded-t-lg`}
              onClick={() => handleTabChange("rpfQuestions")}
            >
              Copilot
            </button>
          </div>
          <div className="duration-500">
            {activeTab === "tableOfContent" ? (
              <TableOfContent
                uid={uid}
                userQuestions={filteredAnswers(messageList)}
              />
            ) : (
              <Rpfquestions userQuestions={messageList} />
            )}
          </div>
        </div>
        <div className="chatbox w-3/5 h-full border-r-8 border-[#400080] bg-[#11002E] mr-5 relative rounded-lg">
          <div className="chat-log w-[97%] flex flex-col overflow-y-scroll scroll-smooth custom-scrollbar">
            {messageList.map((message, index) => {
              console.log(userQuestions);
              const isLastMessage = index === messageList.length - 1;

              return (


                <div key={index} id={message.user==='user'?`tab-${index/2}`:``} >


                  <ChatMessage
                    message={message}
                    parentRefreshClick={handleRefreshClick}
                    isLoading={isLoading}
                    displayMode={displayMode}
                    wordCount={wordCount}
                    charCount={charCount}
                    handleDisplayModeChange={handleDisplayModeChange}
                    showCount={true}
                  />
                  {isLoading && isLastMessage ? (
                    <LinearProgress
                      color="secondary"
                      style={{ marginTop: "-16.25px" }}
                    />
                  ) : null}
                </div>
              );
            })}
            {messageList.length === 0 &&
              <div style={{ textAlign: 'center', color: 'white', marginTop: '5rem' }}>
                <Typography variant="h3" gutterBottom>
                  Add your grant questions below
                  <br />
                  And Let Us Do The Rest
                </Typography>
                <Typography variant="h6">
                  Also Type <Link href="#" underline="always">/</Link> to access special AI features
                </Typography>
                {/* <Typography variant="body2">
                  for example type <Link href="#" underline="always">@RFP</Link> to chat with the RFP
                </Typography> */}
              </div>
            }
            <div ref={messagesEndRef} />
          </div>
          <div className="fixed inset-x-0 bottom-0 flex justify-center">
            <ChatInput
              input={input}
              setInput={setInput}
              handleSubmit={handleSubmit}
              isLoading={isLoading}
              handleDisplayModeChange={handleDisplayModeChange}
              displayMode={displayMode}
              wordCount={wordCount}
              charCount={charCount}
              handleGptVersionChange={handleGptVersionChange}
            />
          </div>
        </div>
        <div className="w-1/6 flex justify-center">
          <FileUploader from={"main"} uid={uid} />
          {showPdfLink && (
            <PDFDownloadLink
              document={sortedData}
              fileName="output.pdf"
              className="z-50 bg-[#380093] border border-[#1b4595] text-sm absolute bottom-8 px-4 py-1 rounded-lg"
            >
              {({ blob, url, loading, error }) =>
                loading ? 'Loading document...' : 'Download PDF'
              }
            </PDFDownloadLink>
          )}
        </div>
      </div>
    </WrapperPlan>
  );
}

// { message } needs to be passed to "ChatMessage", holding for testing


export default Main; 