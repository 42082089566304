import { useEffect, useState } from "react";
import './component.css';
import { auth } from "../utils/firebase";
import { useSubscriptionContext } from "../Contexts/SubscriptionContext";
import { Box, Button, Switch, Typography } from "@mui/material";


const baseurl = process.env.REACT_APP_API_URL;

const features = [
  "AI grant writer",

  "AI Fine Tuning",

  "Google Drive API",

  "Industry reports",

  "Unlimited Clients",
]

const isDevelopment = process.env.NODE_ENV === "development";


const prices = [
  {
    name: 'Basic',
    montlyPriceId: isDevelopment ? 'price_1PW27UL8ltvzfICgfYuge1oH' : 'price_1PZEueL8ltvzfICggb9uOA1J',
    montlyPrice: '10.00',
    yearlyPriceId: isDevelopment ? 'price_1PW3hYL8ltvzfICgKQKuEPlK' : 'price_1PZEueL8ltvzfICguGM8AWa9',
    yearlyPrice: '100.00',
    featuresCount: 1,
  },
  {
    name: 'Mid Plan',
    montlyPriceId: isDevelopment ? 'price_1PW28AL8ltvzfICgqQIZs8TN' : 'price_1PZEugL8ltvzfICgINbNKvRo',
    montlyPrice: '20.00',
    yearlyPriceId: isDevelopment ? 'price_1PW3iWL8ltvzfICgEgHMWpZo' : 'price_1PZEugL8ltvzfICgsjA0areM',
    yearlyPrice: '200.00',
    featuresCount: 2,
  },
  {
    name: 'Top Plan',
    montlyPriceId: isDevelopment ? 'price_1PW28uL8ltvzfICgRsogyubj' : 'price_1PZEuiL8ltvzfICgfUAPbeVV',
    montlyPrice: '40.00',
    yearlyPriceId: isDevelopment ? 'price_1PW3j8L8ltvzfICgJl9eCxfl' : 'price_1PZEuiL8ltvzfICgJMqguZGJ',
    yearlyPrice: '400.00',
    featuresCount: 5,
  }
]
export default function WrapperPlan({
  children,
  autoOpen = false,
}) {

  const [showModal, setShowModal] = useState(false);

  const [buttonIsLoading, setButtonIsLoading] = useState(false);

  const {
    subscriptionState
  } = useSubscriptionContext();

  useEffect(() => {
    if (autoOpen) {
      setShowModal(!subscriptionState)
    }
  }, [subscriptionState, autoOpen])

  const [showMonthly, setShowMonthly] = useState(true);

  return <section>
    {
      subscriptionState ?
        children :
        <div
          style={{
            cursor: 'pointer',
          }}
          onClick={() => {
            setShowModal(true);
          }}
        >
          <div style={{
            pointerEvents: 'none',
          }}>
            {children}
          </div>
        </div>
    }


    {showModal && <>
      <div className="fade" onClick={() => {
        // setShowModal(false);
      }}>
        <div className="container-wrapper">

          <Box textAlign="center" mt={8}>
            <Box display="flex" justifyContent="center" alignItems="center" mb={4}>
              <Typography variant="h6">Monthly</Typography>
              <Switch checked={!showMonthly} onChange={() => {
                setShowMonthly(!showMonthly)
              }} />
              <Typography variant="h6">Annually</Typography>
            </Box>
          </Box>
          <div className="container-items">
            {
              prices.map((i) => <div key={i.name} className="fade__item" onClick={(e) => {
                e.stopPropagation();
              }}>

                <h2 style={{
                  fontSize: '30px',
                  fontWeight: 'bold',
                  marginTop: '20px',
                }}>
                  {i.name}
                </h2>

                <p style={{
                  marginTop: '10px',
                  fontSize: '18px',
                  color: '#1a1a1a',
                }}>
                  {
                    showMonthly ? `$${i.montlyPrice}/month` : `$${i.yearlyPrice}/year`
                  }
                </p>

                <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '10px',
                  marginTop: '20px',
                }}>
                  {
                    features.map((feature, index) => <div key={index} style={{
                      fontSize: '20px',
                      //semi black
                      color: index < i.featuresCount ? '#333' : '#a0a0a0',
                      textDecoration: index < i.featuresCount ? 'none' : 'line-through',
                    }}>
                      {feature}
                    </div>)
                  }
                </div>

                <Button style={{
                  marginTop: 'auto',
                  marginBottom: '20px',
                  borderRadius: '5px',
                  backgroundColor: buttonIsLoading ? '#6772e5' : '#7e00ff',
                  padding: '10px',
                  color: 'white',
                }}
                  disabled={buttonIsLoading}
                  onClick={() => {

                    if (!auth.currentUser) return alert('Please login first')

                    setButtonIsLoading(true)

                    const body = {
                      user_id: auth.currentUser.uid,
                      affiliate_id: localStorage.getItem('affiliate_id') || undefined,
                      price_id: showMonthly ? i.montlyPriceId : i.yearlyPriceId,
                    }

                    fetch(`${baseurl}/create_payment_session`, {
                      method: 'POST',
                      headers: {
                        'Content-Type': 'application/json',

                      },
                      body: JSON.stringify(body),
                    }).then((res) => res.json()).then((data) => {
                      setButtonIsLoading(false)
                      window.location.assign(data.url)
                    }).catch((error) => {
                      setButtonIsLoading(false)
                      console.error('Error:', error)
                    })
                  }}>
                  <span target="_blank" rel="noreferrer">
                    Buy Plan
                  </span>
                </Button>

              </div>)
            }
          </div>
        </div>
      </div>
    </>}
  </section>
}