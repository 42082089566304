import React, { useEffect, useState } from "react";
import ChatMessage from "./ChatMessage";

export default function Rpfquestions(messageListobject) {

  const [messageList, setMessageList] = useState([]);

  useEffect(() => {
    setMessageList(messageListobject.userQuestions)
  }, [])
 
  return (
    <>
      <div className="tableofcontent-container h-4/5 w-full overscroll-y-contain scroll-smooth custom-scrollbar">
        {messageList.map((message, index) => {
          const isLastMessage = index === messageList.length - 1;
          return (
            <div key={index} className="table-of-content">
              <ChatMessage
                from={"copilot"}
                message={message}
                isLoading={true}
                displayMode={"words"}
                showCount={false}
              /> 
            </div>
          );
        })}
      </div>
    </>
  );
}
