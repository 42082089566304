import { initializeApp } from "firebase/app";
import {getAuth} from 'firebase/auth';
import { getFirestore } from "firebase/firestore";
const firebaseConfig = {
  apiKey: "AIzaSyAu20yMP2-H3-HGJOe7ff0cGJqszIj2_yk",
  authDomain: "grantaide.firebaseapp.com",
  projectId: "grantaide",
  storageBucket: "grantaide.appspot.com",
  messagingSenderId: "725142171068",
  appId: "1:725142171068:web:b21b07b052a00807018fb9",
  measurementId: "G-LM2JZEZTRQ"
};

const firebaseapp = initializeApp(firebaseConfig);

export const auth = getAuth(firebaseapp);
export const db = getFirestore(firebaseapp);
export default firebaseapp;