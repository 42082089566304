import { GoogleGenerativeAI } from "@google/generative-ai";

export default async function parseInformation(data) {
  let format = {
    applicant_name: "",
    company_name: "",
    role: "",
    company_website_url: "",
    mission_statement: "",
    vision_statement: "",
    user_tartget_market: "",
    industry: "",
    problem_statement: "",
    solution_statement: "",
    program_and_service: "",
    track_record_and_past_success: "",
    current_funding_sources: "",
    budget: "",
    product_features: "",
    target_customers_users: "",
    goals_objectives_outcomes: "",
    long_term_sustainability_plan: "",
    relevent_partnerships_and_collab: [{}],
    team_member_name: "",
    team_member_role: "",
    linkedin_url: "",
    skills: "",
    interests: "",
    grant_name: "",
    grant_company_name: "",
    submission_date: "",
    due_date: "",
    grant_application_webpage_url: "",
    grant_company_website_url: "",
    grant_company_category_industry: "",
    grant_company_target_market: "",
    agency_type: "",
    geographic_focus: "",
    jusdge_name: "",
    role: "",
    judge_linkedin_url: "",
  };

  const apiKey = process.env.GOOGLE_API_KEY;
  let prompt = `restract information from ${data}, parse and put information into json format as ${format} with each value not exceeding 15 words`;

  /**
   * set up gemini api
   */
  const genAi = new GoogleGenerativeAI(apiKey);
  const model = genAi.getGenerativeModel({
    model: "gemini-1.5-flash",
    systemInstruction:
      "You are a helpful assistant designed to output JSON. The response for each value in JSON should not be more than 15 words, and you always return only 1 object, do not nest other objects or arrays",
  });
  try {
    const result = await model.generateContent(prompt);
    const finalText = result.response.candidates[0].content.parts[0].text;
    const finalTextCleaned = finalText.replace(/^```json\n?|```$/g, "");
    return finalTextCleaned;
  } catch (e) {
    console.log("Failed to fetch data:", e.error);
    return null;
  }
}
