import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";
import { auth, db } from "../../utils/firebase";
import { getDoc, doc, getDocs, collection } from "firebase/firestore";
import "./login.css";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        navigate("/proposal-dashboard");
      }
    });
    return () => unsubscribe();
  }, [navigate]);

  async function checkOldUser(uid) {
    const userDocRef = doc(db, "Users", uid);
    const userDocSnapshot = await getDoc(userDocRef);
    const formDocRef = collection(db, "Data", "Forms", uid);
    const formDocSnapshot = await getDocs(formDocRef); 
    return userDocSnapshot.exists() && !formDocSnapshot.empty;
  }
  
  const onLogIn = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      const uid = user.uid;
      const oldUser = await checkOldUser(uid);  
      console.log(oldUser)
      if (oldUser) {
        navigate("/proposal-dashboard");  
      } else {
        navigate("/form");
      }
    } catch (error) {
      console.log(error);
      if (error.code === "auth/wrong-password") {
        alert("Email and password combination is wrong.");
      }
    }
  };

  return (
    <div className="login-page">
      <div className="wrapper">
        <div className="title">Login Form</div>
        <form onSubmit={onLogIn}>
          <div className="field">
            <input
              type="text"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <label htmlFor="email">Email Address</label>
          </div>
          <div className="field">
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <label htmlFor="password">Password</label>
          </div>
          <div className="content">
            <div className="checkbox">
              <input type="checkbox" id="remember-me" />
              <label htmlFor="remember-me">Remember me</label>
            </div>
            <div className="pass-link">
              <a href="#">Forgot password?</a>
            </div>
          </div>
          <div className="field">
            <input type="submit" id="submit Login" value="Login" />
          </div>
          <div className="signup-link">
            Not a member?{" "}
            <Link to="/signup">
              <a href="#">Sign Up </a>
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Login;
