import MenuIcon from '@mui/icons-material/Menu';


export default function ProposalCard({
  item,
  index,
  handleClick
}) {
const currentDate = new Date();
const dueDate = new Date(item.Due_Date);

const timeDifferenceMillis = dueDate - currentDate;


const shouldNotify = (timeDifferenceMillis < 6 * 24 * 60 * 60 * 1000 && timeDifferenceMillis > 3 * 24 * 60 * 60 * 1000) 
|| (timeDifferenceMillis < 3 * 24 * 60 * 60 * 1000 && timeDifferenceMillis > 1 * 24 * 60 * 60 * 1000)
  return <div
    key={index}
    className={` flex flex-col ${shouldNotify ? 'w-[260px] rounded-[5px] h-[150px] border-2 border-[#ddd] whitespace-normal break-words p-[5px] m-[6px] flex flex-col overflow-hidden text-[10px] leading-[15px] cursor-pointer bg-[#A85101]  hover:bg-[#853F00] transition-colors duration-300 ease-in' : 'dashboard-box'} p-4 cursor-pointer`}
    onClick={() => handleClick(item.id)}
  >
<div>
    </div>
    <div>
    <div className="flex flex-row text-[16px]  line-[18px] text-white font-Radley p-1 justify-between ">
      <p className="clamp-two-lines leading-5"

        // minLines is 2 
        style={{
          height: '2.5em',
        }}>{item.id}</p>
        {/* This is where options modal will be implemented */}
      <MenuIcon fontSize="small" /> 
    </div>
    <div className="category2">
      <p className="line-clamp-1">Grantor:{item.Grant_Name || ' '}</p>
      <p className="pt-2 line-clamp-1">{item.Grant_Company_Name || ' d'}</p>
    </div>
    <div className="text-row flex  pt-2">
      {/* September 19 2021 */}
      <p className="category3 flex-1">due: {
        new Date(item.Due_Date).toLocaleDateString("en-US", {
          month: "long",
          day: "numeric",
          year: "numeric",
        })
      }</p>
      {/* In: Days {LEFT_DAYS} Hr {LEFT_HOURS}  */}
      <p className="category3  flex-1">In: {`
      ${Math.floor(
        (new Date(item.Due_Date) - new Date()) / (1000 * 60 * 60 * 24)
      )} Days
      ${Math.floor(
        ((new Date(item.Due_Date) - new Date()) % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      )} Hr ${Math.floor(
        (((new Date(item.Due_Date) - new Date()) % (1000 * 60 * 60 * 24)) % (1000 * 60 * 60)) / (1000 * 60)
      )} Min
    `}</p>
      {/* <p className="category3">In: Days 100 Hr 24 </p> */}
    </div>
    </div>



    {/* Placeholder For Tags */}
    {/* <div className="category4">
    <p>Tag1</p>
  </div> */}
  </div>
}