import React, { useEffect, useState } from "react";
import WrapperModalPlan from "../../Components/WrapperPlan";
import ProposalCard from "../../Components/ProposalCard";
import { getDocs, collection } from "firebase/firestore";   
import { signOut } from "firebase/auth";
import { auth, db } from "../../utils/firebase";
import { Link, useNavigate } from "react-router-dom"; 
import { styled } from "@mui/material/styles";
import {Button, Modal, Box, Typography, TextField } from "@mui/material"; 
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import "./dashboard.css";


function Main(props) {
  const [data, setData] = useState([]); 
  const [startExistingProfile, setStartExistingProfile] = useState(true); const [open, setOpen] = useState(false);
  const [clients, setClients] = useState([]);
  const [clientData, setClientData] = useState({
    name: "",
    organizationName: "",
    email: "",
    phone: "",
    website: ""
  });

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();

  //MUI styles
  const customStyle = {
    backgroundColor: "#18003F",
    color: "white",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#1B4595",
      },
      "&:hover fieldset": {
        borderColor: "#1B4595",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#1B4595",
      },
    },
    "& .MuiInputLabel-root": {
      color: "white",
    },
    "& .MuiInputBase-input": {
      color: "white",
    },
  };

  const AddClientButton = styled(Button)({
    height: "45px",
    color: "white",
    width: "97%",
    fontSize: "18px",
    backgroundColor: "#380093",
    border: "solid 3px #1B4595",
    margin: "5px 0",
  });

  const ViewAllClientButton = styled(Button)({
    backgroundColor: "#784DBD",
    border: "2px solid #DBC5FF",
    width: "97%",
    height: "45px",
    color: "white",
  });

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "black",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setClientData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCreateClient = () => {
    console.log("Client Details:", clientData);
    setClients([...clients, clientData]);
    setClientData({
      name: "",
      organizationName: "",
      email: "",
      phone: "",
      website: ""
    });
    handleClose();
  };
  //This function will handle the due date of the proposal
  const handleDueDate=(item)=>{
    //subtract the current date from the due date
    const timeDifferenceMillis = new Date(item.Due_Date) - new Date();
    //if the difference is less than 6 days and greater than 3 days, or less than 3 days and greater than 1 day, notify the user (As Rene Requested)
    const shouldNotify = (timeDifferenceMillis < 6 * 24 * 60 * 60 * 1000 && timeDifferenceMillis > 3 * 24 * 60 * 60 * 1000) 
    || (timeDifferenceMillis < 3 * 24 * 60 * 60 * 1000 && timeDifferenceMillis > 1 * 24 * 60 * 60 * 1000)
      // 
      return (
      //The display is only visible when the condition is met but still takes up space in the DOM so the layout doesn't shift
      <div className={`${shouldNotify?"visible ml-2":"invisible"}`}>
                  <p>Due In  {`
      ${Math.floor(
        (new Date(item.Due_Date) - new Date()) / (1000 * 60 * 60 * 24)
      )} Days
      ${Math.floor(
        ((new Date(item.Due_Date) - new Date()) % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      )} Hr ${Math.floor(
        (((new Date(item.Due_Date) - new Date()) % (1000 * 60 * 60 * 24)) % (1000 * 60 * 60)) / (1000 * 60)
      )} Min
                  `}</p>
      </div>

      )

  }
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (!user) {
        navigate("/login");
      } else {
        const uid = user.uid;
        await readData(uid);
      }
    });
    return () => unsubscribe();
  }, [navigate]);

  async function readData(uid) {
    try {
      const docRef = collection(db, "Data", "Forms", uid);
      const docSnapshot = await getDocs(docRef);
      const userData = [];
      docSnapshot.forEach((doc) => {
        const dataWithId = {
          id: doc.id,
          ...doc.data(),
        };
        userData.push(dataWithId);
      });
      //sort proposal cards by due date
      let sortedData = userData.sort((a,b) => {
        return new Date(a.Due_Date) - new Date(b.Due_Date)
      })
      setData(sortedData);
      return sortedData; // Return the data to be used immediately
    } catch (error) {
      console.error("Error reading document:", error);
      return [];
    }
  }

  const logouthandle = async (e) => {
    e.preventDefault();
    signOut(auth);
    navigate("/");
  };

  const handleNew = () => {
    navigate("/new-proposal-dashboard");
  };

  const handleClick = (profile_id) => {
    localStorage.setItem("profile", profile_id);
    const prof = localStorage.getItem("profile");
    if (prof === profile_id) {
      navigate("/grant-assistant");
    } else {
      console.log("error updating the data");
    }
  };

  return (
    <div className="overflow-hidden max-h-screen">
      <div className="bg-gradient-to-l from-green-400 from-12.76% via-sky-500 from-35.58% via-purple-500 from-63.06%  to-fuchsia-500 to-89.1% ... w-full h-12 flex justify-center align-middle sticky top-0">
        <h2 className="pl-10 py-2 text-3xl text-white font-bold ">GrantAIde</h2>
        <div className="header-btns-div text-black">
          <div className="header-btns-left">
            <Link to="/proposal-dashboard">
              <input
                type="submit"
                className="header-btns"
                name="profile-btn"
                value="Dashboard"
              />
            </Link>
          </div>
          <div className="header-btns-right">
            <input
              type="submit"
              className="header-btns"
              name="sign out"
              value="Sign Out"
              onClick={logouthandle}
            />
          </div>
        </div>
      </div>
      <div className="dash-2 top-12">
        <div className="w-1/5 bg-[#11002e] border-r-4 border-[#5406D2] overflow-auto flex">
          <div className="w-1/6 bg-[#250062] flex">
            {/* <Fab size="small"></Fab> */}
          </div>
          {/* <NewClientSidebar /> */}
          <div className="left-client-sidemenu w-1/5 content-start">
            <Typography
              sx={{ display: "flex", justifyContent: "left" }}
              id="modal-title"
              color="white"
              className="pl-3 pt-8 pb-8 text-xs"
            >
              Client List
            </Typography>
            <AddClientButton
              type="button"
              className="client-add-btn"
              onClick={handleOpen}
            >
              <AddCircleOutlineIcon />
              <div className="text-sm border-indigo-900 pl-1">Create New Client</div>
            </AddClientButton>
            {/* <Link to="/all-client-dashboard">
              <ViewAllClientButton
                type="button"
                onClick={handleOpen}
              >
                <div>View All Clients</div>
              </ViewAllClientButton>
            </Link> */}

            <div className="client-list">
              {clients.map((client, index) => (
                <div key={index} className="client-name-div">
                  {client.name}
                </div>
              ))}
            </div>

            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-title"
              aria-describedby="modal-description"
            >
              <Box sx={modalStyle}>
                <Typography
                  sx={{ display: "flex", justifyContent: "center" }}
                  id="modal-title"
                  variant="h6"
                  component="h2"
                  color="white"
                >
                  New Client Details
                </Typography>
                <TextField
                  id="name"
                  label="Name"
                  name="name"
                  value={clientData.name}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  required
                  sx={{ mt: 2, ...customStyle }}
                />
                <TextField
                  id="organization-name"
                  label="Organization Name"
                  name="organizationName"
                  value={clientData.organizationName}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  required
                  sx={{ mt: 2, ...customStyle }}
                />
                <TextField
                  id="email"
                  label="Email Address"
                  name="email"
                  value={clientData.email}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  required
                  sx={{ mt: 2, ...customStyle }}
                />
                <TextField
                  id="phone"
                  label="Phone Number"
                  name="phone"
                  value={clientData.phone}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  sx={{ mt: 2, ...customStyle }}
                />
                <TextField
                  id="website"
                  label="Website"
                  name="website"
                  value={clientData.website}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  sx={{ mt: 2, ...customStyle }}
                />
                <div className="file-upload-btns">
                  <Button
                    onClick={handleCreateClient}
                    variant="contained"
                    color="primary"
                    sx={{ mt: 2 }}
                  >
                    Create
                  </Button>
                  <Button
                    onClick={handleClose}
                    variant="contained"
                    color="primary"
                    sx={{ mt: 2 }}
                  >
                    Cancel
                  </Button>
                </div>
              </Box>
            </Modal>
          </div>
        </div>
        <div className="w-4/5 ml-3 bg-[#11002e]">
          <div className="flex items-center justify-between mb-4 mt-3">
            <button className="button1">Show Only Due Proposals</button>
            {/* <input type="text" className="search-bar text-white" placeholder="Search" /> */}
          </div>
          <div className="inner-section mb-4">
            <p className="font-thin">Your proposals are in order by due date</p>
          </div> 
          <div className="flex w-full mt-1">
            <div className="dash p-1 d-flex items-center gap-3 bg-red-400 w-fit"> 
              <WrapperModalPlan>
                <div className="dashboard-box flex flex-col items-center justify-center relative top-[.82rem] " onClick={() => handleNew()}>
                  <AddCircleOutlineIcon />
                  <div className="category2 text-lg">New Proposal Dashboard</div>
                  <div className="category4 text-lg">Access templates or create a new proposal</div>
                </div>
              </WrapperModalPlan> 
              {data.map((item, index) => (
                <>
                <div>
                <div className="flex justify-start">
                 { handleDueDate(item)  
                  }
                </div>
                <ProposalCard
                  key={index}
                  item={item}
                  index={index}
                  handleClick={handleClick}
                />
                </div>

                </>

              ))}
            </div> 
          </div>
        </div>
      </div> 
    </div>
  );
} 

export default Main;