import { useEffect } from "react";

export default function useSaveAffiliateId() {

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    const affiliateId = urlParams.get('aff_id');

    if (!affiliateId) return;

    localStorage.setItem('affiliate_id', affiliateId);

    urlParams.delete('aff_id');

    const newRelativePathQuery = window.location.pathname + '?' + urlParams.toString();

    window.history.pushState(null, '', newRelativePathQuery);
  }, [])
}