import React, { useEffect, useState, useRef } from "react";
import { auth, db } from "../utils/firebase";
import { Link, useNavigate } from "react-router-dom";
import { getDoc, collection, doc } from "firebase/firestore";
//import userLogout from "./logout"; // To use a common logout function everywhere, insrtead of writing in each file(Needs to be implemented)
import { signOut } from "firebase/auth";
import FileUploader from "../Components/FileUploader";
import "./grantprofile.css";
import { LocalizationProvider, TimeField } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";
import { Box, TextField } from "@mui/material";
import dayjs from "dayjs";
import Autocomplete from "@mui/material/Autocomplete";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";

const axios = require("axios");
// implement to make the form input cleaner
// function renderInput(placeholder, id, value) {
//   return (
//     <input
//       type="text"
//       placeholder={placeholder}
//       id={id}
//       value={value}
//       onChange={handleChange}
//       required
//     />
//   );
// }

const baseurl = process.env.REACT_APP_API_URL;

function Main() {
  const [profileName, setProfileName] = useState("");
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({
    User_name: "",
    Company_Name: "",
    User_role: "",
    Company_Website: "",
    Mission_Statement: "",
    Vision_Statement: "",
    Target_Users_Market: "",
    Focused_Industry: "",
    Problem_Statement: "",
    Solution_Statement: "",
    Programs_Services: "",
    Track_record: "",
    Current_funding: "",
    Budget: "",
    Stakeholders: "",
    Product_Features: "",
    Goals_Objectives_Outcomes: "",
    Long_Term_Plan: "",
    Partners_Collaborators_1: "",
    Partners_Collaborators_2: "",

    Team_Member_1: "",
    Team_Member_Role_1: "",
    Team_Member_Education_1: "",
    Team_Member_1_LinkedIn: "",
    Team_Member_Interests_Hobbies_1: "",
    Team_Member_Super_Power_Skills_1: "",

    Team_Member_2: "",
    Team_Member_Role_2: "",
    Team_Member_Education_2: "",
    Team_Member_2_LinkedIn: "",
    Team_Member_Interests_Hobbies_2: "",
    Team_Member_Super_Power_Skills_2: "",

    Team_Member_3: "",
    Team_Member_Role_3: "",
    Team_Member_Education_3: "",
    Team_Member_3_LinkedIn: "",
    Team_Member_Interests_Hobbies_3: "",
    Team_Member_Super_Power_Skills_3: "",

    Team_Member_4: "",
    Team_Member_Role_4: "",
    Team_Member_Education_4: "",
    Team_Member_2_LinkedIn: "",
    Team_Member_Interests_Hobbies_4: "",
    Team_Member_Super_Power_Skills_4: "",

    Grant_Name: "",
    Grant_Company_Name: "",
    Grant_Amount: "",
    Grant_Requirements: "",
    Due_Date: null,
    Due_Time: dayjs(),
    Due_Time_Zone: "",
    AM_PM: "",
    Grant_Company_Website: "",
    Grant_Application_Website: "",
    Grant_Company_Mission_Statement: "",
    Grant_Company_Vision_Statement: "",
    Grant_Company_Target_Market: "",
    Grant_Company_Category_Industry: "",
    Agency_Type: "",
    Geographic_Focus: "",
    Grant_Company_LinkedIn: "",

    Judges_Name: "",
    Judges_Role: "",
    Judges_Partnerships_Collaborations_1: "",
    Judges_Partnerships_Collaborations_2: "",
    Judges_LinkedIn: "",
    Judges_More_Relevant_info: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [overwriteConfirmed, setOverwriteConfirmed] = useState(false);
  const navigate = useNavigate();
  const user = auth.currentUser;
  const uid = user ? user.uid : null;
  const totalSteps = 3;
  const getUSTimeZones = () => {
    const timeZones = Intl.supportedValuesOf("timeZone").filter((zone) =>
      zone.startsWith("America/")
    );
    return timeZones.map((timeZone) => {
      const now = new Date();
      const formatter = new Intl.DateTimeFormat("en-US", {
        timeZone,
        timeZoneName: "short",
      });
      const parts = formatter.formatToParts(now);
      const gmtOffset = parts.find(
        (part) => part.type === "timeZoneName"
      ).value;
      return {
        label: `(${gmtOffset}) ${timeZone
          .replace("America/", "")
          .replace("_", " ")}`,
        value: timeZone,
      };
    });
  };
  const usTimeZones = getUSTimeZones();

  // Detect user's timezone and set as default value
  useEffect(() => {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const defaultTimeZone = usTimeZones.find(
      (zone) => zone.value === userTimeZone
    );
    if (defaultTimeZone) {
      setFormData((prevData) => ({
        ...prevData,
        Due_Time_Zone: defaultTimeZone.value,
      }));
    }
  }, []);

  //checking only loggedin user to use it
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (!user) {
        navigate("/login");
      }
    });
    return () => unsubscribe();
  }, []);
  // const uid = user ? user.uid : null;
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [id]: value }));
  };

  const handleDateChange = (data) => {
    setFormData({ ...formData, Due_Date: data });
  };

  const handleTimeChange = (newTime) => {
    setFormData((prevData) => ({
      ...prevData,
      Due_Time: newTime,
    }));
  };

  const logouthandle = async (e) => {
    e.preventDefault();
    signOut(auth);
    navigate("/");
  };

  async function getData(user, profileName) {
    const collectionName = "Data/Forms/" + user;
    const docRef = doc(db, collectionName, profileName);
    const docSnapshot = await getDoc(docRef);
    return docSnapshot.data();
  }

  useEffect(() => {
    // Check if old profile ID exists in localStorage
    const oldProfileId = localStorage.getItem("oldprofile");

    if (oldProfileId) {
      setProfileName(oldProfileId);
    }
    // If old profile ID exists and profileName is not empty, fetch the document from Firestore
    if (oldProfileId && profileName) {
      getData(uid, oldProfileId)
        .then((oldProfileData) => {
          if (oldProfileData.Due_Date) {
            oldProfileData.Due_Date = dayjs(oldProfileData.Due_Date);
          }
          if (oldProfileData.Due_Time) {
            oldProfileData.Due_Time = dayjs(oldProfileData.Due_Time);
          }
          setFormData(oldProfileData);
        })
        .catch((error) => {
          console.error("Error fetching old profile data:", error);
        });
      setProfileName(oldProfileId);
    }
  }, [uid, profileName]);

  function changeStep(num) {
    setCurrentStep(num);
  }

  const handleNext = () => {
    const requiredInputs = document.querySelectorAll("input[required]");
    const isEmpty = Array.from(requiredInputs).some(
      (input) => !input.value.trim()
    );
    if (isEmpty) {
      alert("Please fill in the required fields. (*)");
      return;
    }
    setCurrentStep((currentStep) => currentStep + 1);
    console.log(currentStep);
  };

  const handlePrev = () => {
    setCurrentStep((currentStep) => currentStep - 1);
  };

  const customStyle = {
    backgroundColor: "#000000",
    color: "white",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "black",
      },
      "&:hover fieldset": {
        borderColor: "#1B4595",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#1B4595",
      },
    },
    "& .MuiInputLabel-root": {
      color: "white",
      position: 'relative',
      top: 'auto',
      left: 'auto',
      transform:'',
    },
    "& .MuiInputBase-input": {
      color: "white",
    },
  };

  // const modalStyle = {
  //   position: "absolute",
  //   top: "50%",
  //   left: "50%",
  //   transform: "translate(-50%, -50%)",
  //   width: 400,
  //   bgcolor: "black",
  //   border: "2px solid #000",
  //   boxShadow: 24,
  //   p: 4,
  // };

  const whiteBorderDatePickerStyles = {
    '& .MuiButtonBase-root' : {
      color: '#D3D3D3',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: '1.5px solid #19039E',
      },
      '&:hover fieldset': {
        borderColor: '#D3D3D3', // Border color on hover
      },
      '&.Mui-focused fieldset': {
        borderColor: '#D3D3D3', // Border color when focused
      },
    },
    
  }
  const whiteBorderTimePickerStyles = {
    '& .MuiButtonBase-root' : {
      color: '#D3D3D3',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: '1.5px solid #19039E',
      },
      '&:hover fieldset': {
        borderColor: '#D3D3D3', // Border color on hover
      },
      '&.Mui-focused fieldset': {
        borderColor: '#D3D3D3', // Border color when focused
      },
    },
    
  }

  const renderstepFields = (step) => {
    switch (step) {
      case 1:
        return (
          <div>
            <table>
              <tr>
                <td>
                  <p className="ml-0 text-[#b0afaf] text-xs">Applicant's Name *</p>
                  <div className="form-item-styles">
                    <input
                      className="transparent-placeholder"
                      type="text"
                      placeholder="Applicant's Name *"
                      id="User_name"
                      value={formData.User_name}
                      onChange={handleChange}
                      required
                    />

                  </div>
                </td>
                <td>
                  <p className="ml-0 text-[#b0afaf] text-xs">Company Name *</p>
                  <div className="form-item-styles">
                    <input
                      className="transparent-placeholder"
                      type="text"
                      placeholder="Company Name *"
                      id="Company_Name"
                      value={formData.Company_Name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                <p className="ml-0 text-[#b0afaf] text-xs">Role *</p>
                  <div className="form-item-styles">
                    <input
                      className="transparent-placeholder"
                      type="text"
                      placeholder="Role *"
                      id="User_role"
                      value={formData.User_role}
                      onChange={handleChange}
                      required
                    />

                  </div>
                </td>
                <td>
                  <p className="ml-0 text-[#b0afaf] text-xs">Company Website URL</p>
                  <div className="form-item-styles">
                    <input
                      className="transparent-placeholder"
                      type="text"
                      placeholder="Company Website URL"
                      id="Company_Website"
                      value={formData.Company_Website}
                      onChange={handleChange}
                    />

                  </div>
                </td>
              </tr>

              <tr>
                <td>
                  <p className="ml-0 text-[#b0afaf] text-xs">Mission Statement</p>
                  <div className="form-item-styles">
                    <input
                      className="transparent-placeholder"
                      type="text"
                      placeholder="Mission Statement"
                      id="Mission_Statement"
                      value={formData.Mission_Statement}
                      onChange={handleChange}
                    />

                  </div>
                </td>
                <td>
                  <p className="ml-0 text-[#b0afaf] text-xs">Vision Statement</p>
                  <div className="form-item-styles">
                    <input
                      className="transparent-placeholder"
                      type="text"
                      placeholder="Vision Statement"
                      id="Vision_Statement"
                      value={formData.Vision_Statement}
                      onChange={handleChange}
                    />

                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="ml-0 text-[#b0afaf] text-xs">Users/target Market *</p>
                  <div className="form-item-styles">
                    <input
                      className="transparent-placeholder"
                      type="text"
                      placeholder="Users/target Market *"
                      id="Target_Users_Market"
                      value={formData.Target_Users_Market}
                      onChange={handleChange}
                      required
                    />

                  </div>
                </td>
                <td>
                  <p className="ml-0 text-[#b0afaf] text-xs">Industry *</p>
                  <div className="form-item-styles">
                    <input
                      className="transparent-placeholder"
                      type="text"
                      placeholder="Industry *"
                      id="Focused_Industry"
                      value={formData.Focused_Industry}
                      onChange={handleChange}
                      required
                    />

                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="ml-0 text-[#b0afaf] text-xs">Problem Statement *</p>
                  <div className="form-item-styles">
                    <input
                      className="transparent-placeholder"
                      type="text"
                      placeholder="Problem Statement *"
                      id="Problem_Statement"
                      value={formData.Problem_Statement}
                      onChange={handleChange}
                      required
                    />

                  </div>
                </td>
                <td>
                  <p className="ml-0 text-[#b0afaf] text-xs">Solution Statement *</p>
                  <div className="form-item-styles">
                    <input
                      className="transparent-placeholder"
                      type="text"
                      placeholder="Solution Statement *"
                      id="Solution_Statement"
                      value={formData.Solution_Statement}
                      onChange={handleChange}
                      required
                    />

                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="ml-0 text-[#b0afaf] text-xs">Programs, Services, and Product Features *</p>
                  <div className="form-item-styles">
                    <input
                      className="transparent-placeholder"
                      type="text"
                      placeholder="Programs, Services, and Features *"
                      id="Programs_Services"
                      value={formData.Programs_Services}
                      onChange={handleChange}
                      required
                    />

                  </div>
                </td>
                <td>
                  <p className="ml-0 text-[#b0afaf] text-xs">Track record and past success</p>
                  <div className="form-item-styles">
                    <input
                      className="transparent-placeholder"
                      type="text"
                      placeholder="Track record and past success"
                      id="Track_record"
                      value={formData.Track_record}
                      onChange={handleChange}
                    />

                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="ml-0 text-[#b0afaf] text-xs">Current funding sources</p>
                  <div className="form-item-styles">
                    <input
                      className="transparent-placeholder"
                      type="text"
                      placeholder="Current funding sources"
                      id="Current_funding"
                      value={formData.Current_funding}
                      onChange={handleChange}
                    />

                  </div>
                </td>
                <td>
                  <p className="ml-0 text-[#b0afaf] text-xs">Budget</p>
                  <div className="form-item-styles">
                    <input
                      className="transparent-placeholder"
                      type="text"
                      placeholder="Budget"
                      id="Budget"
                      value={formData.Budget}
                      onChange={handleChange}
                    />

                  </div>
                </td>
              </tr>
              <tr>
                
              </tr>
              <tr>
                <td>
                  <p className="ml-0 text-[#b0afaf] text-xs">Goals, Objectives, and Outcomes</p>
                  <div className="form-item-styles">
                    <input
                      className="transparent-placeholder"
                      type="text"
                      placeholder="Goals, Objectives, and Outcomes"
                      id="Goals_Objectives_Outcomes"
                      value={formData.Goals_Objectives_Outcomes}
                      onChange={handleChange}
                    />

                  </div>
                </td>
                <td>
                  <p className="ml-0 text-[#b0afaf] text-xs">Long-term sustainability plan</p>
                  <div className="form-item-styles">
                    <input
                      className="transparent-placeholder"
                      type="text"
                      placeholder="Long-term sustainability plan"
                      id="Long_Term_Plan"
                      value={formData.Long_Term_Plan}
                      onChange={handleChange}
                    />

                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="ml-0 text-[#b0afaf] text-xs">Relevant Partnerships and Collaborators 1</p>
                  <div className="form-item-styles">
                    <input
                      className="transparent-placeholder"
                      type="text"
                      placeholder="Relevant Partnerships and Collaborators 1"
                      id="Partners_Collaborators_1"
                      value={formData.Partners_Collaborators_1}
                      onChange={handleChange}
                    />

                  </div>
                </td>
                <td>
                  <p className="ml-0 text-[#b0afaf] text-xs">Relevant Partnerships and Collaborators 2</p>
                  <div className="form-item-styles">
                    <input
                      className="transparent-placeholder"
                      type="text"
                      placeholder="Relevant Partnerships and Collaborators 2"
                      id="Partners_Collaborators_2"
                      value={formData.Partners_Collaborators_2}
                      onChange={handleChange}
                    />

                  </div>
                </td>
              </tr>
            </table>
          </div>
        );
      case 2:
        return (
          <div>
            <table>
              <tr>
                <td>
                  <p className="ml-0 text-[#b0afaf] text-xs font-bold">Team Member 1 Name *</p>
                  <div className="form-item-styles">
                    <input
                      className="transparent-placeholder"
                      type="text"
                      placeholder="Team Member 1 Name *"
                      id="Team_Member_1"
                      value={formData.Team_Member_1}
                      onChange={handleChange}
                      required
                    />

                  </div>
                </td>
                <td>
                  <p className="ml-0 text-[#b0afaf] text-xs font-bold">Team Member 2 Name</p>
                  <div className="form-item-styles">
                    <input
                      className="transparent-placeholder"
                      type="text"
                      placeholder="Team Member 2 Name"
                      id="Team_Member_2"
                      value={formData.Team_Member_2}
                      onChange={handleChange}

                    />

                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="ml-0 text-[#b0afaf] text-xs">Role *</p>
                  <div className="form-item-styles">
                    <input
                      className="transparent-placeholder"
                      type="text"
                      placeholder="Role *"
                      id="Team_Member_Role_1"
                      value={formData.Team_Member_Role_1}
                      onChange={handleChange}

                      required
                    />

                  </div>
                </td>
                <td>
                  <p className="ml-0 text-[#b0afaf] text-xs">Role</p>
                  <div className="form-item-styles">
                    <input
                      className="transparent-placeholder"
                      type="text"
                      placeholder="Role"
                      id="Team_Member_Role_2"
                      value={formData.Team_Member_Role_2}
                      onChange={handleChange}

                    />

                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="ml-0 text-[#b0afaf] text-xs">Education</p>
                  <div className="form-item-styles">
                    <input
                      className="transparent-placeholder"
                      type="text"
                      placeholder="Education"
                      id="Team_Member_Education_1"
                      value={formData.Team_Member_Education_1}
                      onChange={handleChange}

                    />

                  </div>
                </td>
                <td>
                  <p className="ml-0 text-[#b0afaf] text-xs">Education</p>
                  <div className="form-item-styles">
                    <input
                      className="transparent-placeholder"
                      type="text"
                      placeholder="Education"
                      id="Team_Member_Education_2"
                      value={formData.Team_Member_Education_2}
                      onChange={handleChange}

                    />

                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="ml-0 text-[#b0afaf] text-xs">LinkedIn URL</p>
                  <div className="form-item-styles">
                    <input
                      className="transparent-placeholder"
                      type="text"
                      placeholder="LinkedIn URL"
                      id="Team_Member_1_LinkedIn"
                      value={formData.Team_Member_1_LinkedIn}
                      onChange={handleChange}
                    />

                  </div>
                </td>
                <td>
                  <p className="ml-0 text-[#b0afaf] text-xs">LinkedIn URL</p>
                  <div className="form-item-styles">
                    <input
                      className="transparent-placeholder"
                      type="text"
                      placeholder="LinkedIn URL"
                      id="Team_Member_2_LinkedIn"
                      value={formData.Team_Member_2_LinkedIn}
                      onChange={handleChange}

                    />

                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="ml-0 text-[#b0afaf] text-xs">Super Power/Skills</p>
                  <div className="form-item-styles">
                    <input
                      className="transparent-placeholder"
                      type="text"
                      placeholder="Super Power/Skills"
                      id="Team_Member_Super_Power_Skills_1"
                      value={formData.Team_Member_Super_Power_Skills_1}
                      onChange={handleChange}

                    />

                  </div>
                </td>
                <td>
                  <p className="ml-0 text-[#b0afaf] text-xs">Super Power/Skills</p>
                  <div className="form-item-styles">
                    <input
                      className="transparent-placeholder"
                      type="text"
                      placeholder="Super Power/Skills"
                      id="Team_Member_Super_Power_Skills_2"
                      value={formData.Team_Member_Super_Power_Skills_2}
                      onChange={handleChange}

                    />

                  </div>
                </td>
              </tr>
              <tr>
                
              </tr>
            </table>
            <table>
              <tr>
                <td>
                  <label
                    type="text"
                    placeholder="_"
                    id="_"
                    onChange={handleChange}
                  />
                </td>
                <td>
                  <label
                    type="text"
                    placeholder="_"
                    id="_"
                    onChange={handleChange}
                  />
                </td>
              </tr>

              <tr>
                <td>
                  <p className="ml-0 text-[#b0afaf] text-xs font-bold">Team Member 3 Name</p>
                  <div className="form-item-styles">
                    <input
                      className="transparent-placeholder"
                      type="text"
                      placeholder="Team Member 3 Name"
                      id="Team_Member_3"
                      value={formData.Team_Member_3}
                      onChange={handleChange}

                    />

                  </div>
                </td>
                <td>
                  <p className="ml-0 text-[#b0afaf] text-xs font-bold">Team Member 4 Name</p>
                  <div className="form-item-styles">
                    <input
                      className="transparent-placeholder"
                      type="text"
                      placeholder="Team Member 4 Name"
                      id="Team_Member_4"
                      value={formData.Team_Member_4}
                      onChange={handleChange}

                    />

                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="ml-0 text-[#b0afaf] text-xs">Role</p>
                  <div className="form-item-styles">
                    <input
                      className="transparent-placeholder"
                      type="text"
                      placeholder="Role"
                      id="Team_Member_Role_3"
                      value={formData.Team_Member_Role_3}
                      onChange={handleChange}

                    />

                  </div>
                </td>
                <td>
                  <p className="ml-0 text-[#b0afaf] text-xs">Role</p>
                  <div className="form-item-styles">
                    <input
                      className="transparent-placeholder"
                      type="text"
                      placeholder="Role"
                      id="Team_Member_Role_4"
                      value={formData.Team_Member_Role_4}
                      onChange={handleChange}

                    />

                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="ml-0 text-[#b0afaf] text-xs">Education</p>
                  <div className="form-item-styles">
                    <input
                      className="transparent-placeholder"
                      type="text"
                      placeholder="Education"
                      id="Team_Member_Education_3"
                      value={formData.Team_Member_Education_3}
                      onChange={handleChange}

                    />

                  </div>
                </td>
                <td>
                  <p className="ml-0 text-[#b0afaf] text-xs">Education</p>
                  <div className="form-item-styles">
                    <input
                      className="transparent-placeholder"
                      type="text"
                      placeholder="Education"
                      id="Team_Member_Education_4"
                      value={formData.Team_Member_Education_4}
                      onChange={handleChange}

                    />

                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="ml-0 text-[#b0afaf] text-xs">LinkedIn URL</p>
                  <div className="form-item-styles">
                    <input
                      className="transparent-placeholder"
                      type="text"
                      placeholder="LinkedIn URL"
                      id="Team_Member_3_LinkedIn"
                      value={formData.Team_Member_3_LinkedIn}
                      onChange={handleChange}

                    />

                  </div>
                </td>
                <td>
                  <p className="ml-0 text-[#b0afaf] text-xs">LinkedIn URL</p>
                  <div className="form-item-styles">
                    <input
                      className="transparent-placeholder"
                      type="text"
                      placeholder="LinkedIn URL"
                      id="Team_Member_4_LinkedIn"
                      value={formData.Team_Member_4_LinkedIn}
                      onChange={handleChange}

                    />

                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="ml-0 text-[#b0afaf] text-xs">Super Power/Skills</p>
                  <div className="form-item-styles">
                    <input
                      className="transparent-placeholder"
                      type="text"
                      placeholder="Super Power/Skills"
                      id="Team_Member_Super_Power_Skills_3"
                      value={formData.Team_Member_Super_Power_Skills_3}
                      onChange={handleChange}

                    />

                  </div>
                </td>
                <td>
                  <p className="ml-0 text-[#b0afaf] text-xs">Super Power/Skills</p>
                  <div className="form-item-styles">
                    <input
                      className="transparent-placeholder"
                      type="text"
                      placeholder="Super Power/Skills"
                      id="Team_Member_Super_Power_Skills_4"
                      value={formData.Team_Member_Super_Power_Skills_4}
                      onChange={handleChange}

                    />

                  </div>
                </td>
              </tr>
              <tr>
                
              </tr>
            </table>
          </div>
        );
      case 3:
        return (
          <div>
            <table>
              <tr>
                <td>
                  <p className="ml-0 text-[#b0afaf] text-xs">Grant Name *</p>
                  <div className="form-item-styles">
                    <input
                      className="transparent-placeholder"
                      type="text"
                      placeholder="Grant Name *"
                      id="Grant_Name"
                      value={formData.Grant_Name}
                      onChange={handleChange}
                      required
                    />

                  </div>
                </td>
                <td>
                  <p className="ml-0 text-[#b0afaf] text-xs">Grant Company Name *</p>
                  <div className="form-item-styles">
                    <input
                      className="transparent-placeholder"
                      type="text"
                      placeholder="Grant Company Name *"
                      id="Grant_Company_Name"
                      value={formData.Grant_Company_Name}
                      onChange={handleChange}
                      required
                    />

                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      
                      value={formData.Due_Date}
                      onChange={handleDateChange}
                      renderInput={(params) => <TextField {...params} />}
                      required
                      sx={whiteBorderDatePickerStyles}
                    />
                  </LocalizationProvider>
                </td>
                <td>
                  <Autocomplete
                    options={usTimeZones}
                    className="custom-timezone"
                    getOptionLabel={(option) =>
                      `${option.label.split(") ")[1]} (${option.label
                        .split(") ")[0]
                        .substring(1)})`
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Due Time Zone *"
                        inputProps={{
                          ...params.inputProps,
                          style: { height: "15px", padding: "0px" },
                        }}
                        style={{ width: "200px", marginLeft: "0px" }}
                      />
                    )}
                    value={
                      usTimeZones.find(
                        (zone) => zone.value === formData.Due_Time_Zone
                      ) || null
                    }
                    onChange={(event, newValue) => {
                      setFormData((prevData) => ({
                        ...prevData,
                        Due_Time_Zone: newValue ? newValue.value : "",
                      }));
                    }}
                    required
                    sx={whiteBorderDatePickerStyles}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["TimeField"]}>
                      <TimeField
                        value={formData.Due_Time}
                        onChange={handleTimeChange}
                        required
                        sx={whiteBorderTimePickerStyles}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </td>
              </tr>
              <td>
                <p className="ml-0 text-[#b0afaf] text-xs">Grant Application Webpage URL</p>
                <div className="form-item-styles">
                  <input
                    className="transparent-placeholder"
                    type="text"
                    placeholder="Grant Application Webpage URL"
                    id="Grant_Application_Website"
                    value={formData.Grant_Application_Website}
                    onChange={handleChange}
                  />

                </div>
              </td>
              <td>
                <p className="ml-0 text-[#b0afaf] text-xs">Grant Company Website URL</p>
                <div className="form-item-styles">
                  <input
                    className="transparent-placeholder"
                    type="text"
                    placeholder="Grant Company Website URL"
                    id="Grant_Company_Website"
                    value={formData.Grant_Company_Website}
                    onChange={handleChange}
                  />

                </div>
              </td>

              <tr>
                <td>
                  <p className="ml-0 text-[#b0afaf] text-xs">Grant Company Category / Industry</p>
                  <div className="form-item-styles">
                    <input
                      className="transparent-placeholder"
                      type="text"
                      placeholder="Grant Company Category / Industry"
                      id="Grant_Company_Category_Industry"
                      value={formData.Grant_Company_Category_Industry}
                      onChange={handleChange}
                    />

                  </div>
                </td>
                <td>
                  <p className="ml-0 text-[#b0afaf] text-xs">Grant Company Target Market</p>
                  <div className="form-item-styles">
                    <input
                      className="transparent-placeholder"
                      type="text"
                      placeholder="Grant Company Target Market"
                      id="Grant_Company_Target_Market"
                      value={formData.Grant_Company_Target_Market}
                      onChange={handleChange}
                    />

                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="ml-0 text-[#b0afaf] text-xs">Agency Type  (e.g. Federal, State, Local, Organization)</p>
                  <div className="form-item-styles">
                    <input
                      className="transparent-placeholder"
                      type="text"
                      placeholder="Agency Type  (e.g. Federal, State, Local, Organization)"
                      id="Agency_Type"
                      value={formData.Agency_Type}
                      onChange={handleChange}
                    />

                  </div>
                </td>
                <td>
                  <p className="ml-0 text-[#b0afaf] text-xs">Geographic Focus (US state)</p>
                  <div className="form-item-styles">
                    <input
                      className="transparent-placeholder"
                      type="text"
                      placeholder="Geographic Focus (US state)"
                      id="Geographic_Focus"
                      value={formData.Geographic_Focus}
                      onChange={handleChange}
                    />

                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="ml-0 text-[#b0afaf] text-xs">Grant Company Mission Statement</p>
                  <div className="form-item-styles">
                    <input
                      className="transparent-placeholder"
                      type="text"
                      placeholder="Grant Company Mission Statement"
                      id="Grant_Company_Mission_Statement"
                      value={formData.Grant_Company_Mission_Statement}
                      onChange={handleChange}
                    />

                  </div>
                </td>
                <td>
                  <p className="ml-0 text-[#b0afaf] text-xs">Grant Company Vision Statement</p>
                  <div className="form-item-styles">
                    <input
                      className="transparent-placeholder"
                      type="text"
                      placeholder="Grant Company Vision Statement"
                      id="Grant_Company_Vision_Statement"
                      value={formData.Grant_Company_Vision_Statement}
                      onChange={handleChange}
                    />

                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="ml-0 text-[#b0afaf] text-xs">Grant Company LinkedIn URL</p>
                  <div className="form-item-styles">
                    <input
                      className="transparent-placeholder"
                      type="text"
                      placeholder="Grant Company LinkedIn URL"
                      id="Grant_Company_LinkedIn"
                      value={formData.Grant_Company_LinkedIn}
                      onChange={handleChange}
                    />

                  </div>
                </td>
                <td>
                  <label type="text" id="_" onChange={handleChange} />
                </td>
              </tr>
              <tr>
                <td>
                  <p className="ml-0 text-[#b0afaf] text-xs">Judge's Name</p>
                  <div className="form-item-styles">
                    <input
                      className="transparent-placeholder"
                      type="text"
                      placeholder="Judge's Name"
                      id="Judges_Name"
                      value={formData.Judges_Name}
                      onChange={handleChange}
                    />

                  </div>
                </td>
                <td>
                  <p className="ml-0 text-[#b0afaf] text-xs">Role</p>
                  <div className="form-item-styles">
                    <input
                      className="transparent-placeholder"
                      type="text"
                      placeholder="Role"
                      id="Judges_Role"
                      value={formData.Judges_Role}
                      onChange={handleChange}
                    />

                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="ml-0 text-[#b0afaf] text-xs">Judge's Linkedin URL</p>
                  <div className="form-item-styles">
                    <input
                      className="transparent-placeholder"
                      type="text"
                      placeholder="Judge's Linkedin URL"
                      id="Judges_LinkedIn"
                      value={formData.Judges_LinkedIn}
                      onChange={handleChange}
                    />

                  </div>
                </td>
                <td>
                  <p className="ml-0 text-[#b0afaf] text-xs">More Relevant info about Grant Admin/Judge</p>
                  <div className="form-item-styles">
                    <input
                      className="transparent-placeholder"
                      type="text"
                      placeholder="More Relevant info about Grant Admin/Judge"
                      id="Judges_More_Relevant_info"
                      value={formData.Judges_More_Relevant_info}
                      onChange={handleChange}
                    />

                  </div>
                </td>
              </tr>
            </table>
          </div>
        );
    }
  };

  //   const handleSubmit = async (e) => {
  //     e.preventDefault();
  //     console.log(formData);
  //     setIsSubmitting(true);
  //     try {
  //       const docRef = await addDoc(collection(db, 'Forms'), formData);
  //       console.log(docRef.id);
  //       console.log("Data stored on firebase");
  //     } catch(error) {
  //       console.error("Failed to save data on firebase: ", error);
  //     }
  //     localStorage.setItem("formData", JSON.stringify(formData));
  //     console.log("Form data saved locally")
  //     //navigate('/grant-assistant');
  //     setIsSubmitting(false);
  //  };

  async function ifExist(user, prfolieName) {
    const collectionName = "Data/Forms/" + user;
    const docRef = doc(db, collectionName, prfolieName);
    const docSnapshot = await getDoc(docRef);
    return docSnapshot.exists();
  }

  async function handleSubmit(e) {
    console.log(formData);
    const requiredInputs = document.querySelectorAll("input[required]");
    const isEmpty = Array.from(requiredInputs).some(
      (input) => !input.value.trim()
    );

    if (isEmpty) {
      alert("Please fill in the required fields. (*)");
      return;
    }
    //sending data to server which later sends to firebase
    // GCL edpoint : 34.148.28.46:5000
    // local endpoint: 127.0.0.1:5000
    const ProfileExist = await ifExist(uid, profileName);
    if (ProfileExist) {
      setOverwriteConfirmed(true)
      if (!overwriteConfirmed) {
        // User chose not to overwrite, so exit the function
        return;
      }
    }
    const response = await fetch(`${baseurl}/form`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        form: formData,
        profile: profileName,
        user: uid,
      }),
    });
    // sending the profile name to the aiapp
    //storing the data locally
    localStorage.setItem("profile", profileName);
    const prof = localStorage.getItem("profile");
    // possible bug here
    if (prof == profileName && response.ok) {
      console.log("Data Updated");
      navigate("/grant-assistant");
    } else {
      console.log("error updating the data");
    }
  }

  /* The Function called on submission of user message, updates "messageList" state prop, to keep track of messages, adds input text to that log
   */

  function handleRefreshClick() {
    console.log("Clicked outer refresh");
  }

  const [childData, setChildData] = useState(null);

  function handleChildData(data) {
    setChildData(data);
    const dataJSON = JSON.parse(data);
    const {
      name,
      email,
      founder,
      company,
      pitch,
      description,
      problem,
      solution,
      benefits,
      competitive_advantage,
      customers,
      market_size,
      competitors,
      revenue_model,
      pricing_strategy,
      tractions,
      needs,
      mission,
      funding_needs,
      target_market,
      milestones,
      markets,
    } = dataJSON;

    setFormData((prevData) => ({
      ...prevData,
      User_name: "",
      Company_Name: name || company || "",
      User_role: "",
      Company_Website: "",
      Mission_Statement: pitch || mission || "",
      Vision_Statement: description || "",
      Target_Users_Market: customers || markets || problem || "",
      Focused_Industry: "",
      Problem_Statement: "",
      Solution_Statement: solution || "",
      Programs_Services: target_market || "",
      Track_record: "",
      Current_funding: "",
      Budget: needs || funding_needs || "",
      Stakeholders: "",
      Product_Features: competitive_advantage || "",
      Goals_Objectives_Outcomes: milestones || "",
      Long_Term_Plan: "",
      Partners_Collaborators_1: "",
      Partners_Collaborators_2: "",

      Team_Member_1: founder || "",
      Team_Member_Role_1: "",
      Team_Member_Education_1: "",
      Team_Member_1_LinkedIn: "",
      Team_Member_Interests_Hobbies_1: "",
      Team_Member_Super_Power_Skills_1: "",

      Team_Member_2: "",
      Team_Member_Role_2: "",
      Team_Member_Education_2: "",
      Team_Member_2_LinkedIn: "",
      Team_Member_Interests_Hobbies_2: "",
      Team_Member_Super_Power_Skills_2: "",

      Team_Member_3: "",
      Team_Member_Role_3: "",
      Team_Member_Education_3: "",
      Team_Member_3_LinkedIn: "",
      Team_Member_Interests_Hobbies_3: "",
      Team_Member_Super_Power_Skills_3: "",

      Team_Member_4: "",
      Team_Member_Role_4: "",
      Team_Member_Education_4: "",
      Team_Member_2_LinkedIn: "",
      Team_Member_Interests_Hobbies_4: "",
      Team_Member_Super_Power_Skills_4: "",

      Grant_Name: "",
      Grant_Company_Name: "",
      Grant_Amount: "",
      Grant_Requirements: "",
      Due_Date: null,
      Due_Time: "",
      Due_Time_Zone: "",
      AM_PM: "",
      Grant_Company_Website: "",
      Grant_Application_Website: "",
      Grant_Company_Mission_Statement: "",
      Grant_Company_Vision_Statement: "",
      Grant_Company_Target_Market: "",
      Grant_Company_Category_Industry: "",
      Agency_Type: "",
      Geographic_Focus: "",
      Grant_Company_LinkedIn: "",

      Judges_Name: "",
      Judges_Role: "",
      Judges_Partnerships_Collaborations_1: "",
      Judges_Partnerships_Collaborations_2: "",
      Judges_LinkedIn: "",
      Judges_More_Relevant_info: "",
    }));
  }
  return (

    <div className="App">
           {overwriteConfirmed && (
      <dialog open className="space-y-2 bg-[#1f1f1f] text-[#dedede] p-10 w-[528px] h-[200px] z-10 rounded-xl">
        <h3 className="text-2xl font-bold">www.grantaide.com</h3>
        <p className="">Proposal name already exists. Rename proposal.</p>
        <form method="dialog" className="flex justify-end px-20">
          {/* possible hover animation if needed hover:bg-[#244E6E] */}
          <button className='mt-4 text-[#9eb5de] border border-[#306791] rounded-3xl py-2 px-4 ' onClick={() => setOverwriteConfirmed(false)}>Cancel</button>
        </form>
      </dialog>
    )}
      <div className="bg-gradient-to-l from-green-400 from-12.76% via-sky-500 from-35.58% via-purple-500 from-63.06%  to-fuchsia-500 to-89.1% ... w-full h-12 flex justify-center align-middle">
        <h2 className="pl-10 py-2 text-3xl text-white font-bold ">GrantAIde</h2>
        <div className="header-btns-div text-black">
          <div className="header-btns-left">
            <Link to="/proposal-dashboard">
              <input
                type="submit"
                className="header-btns"
                name="profile-btn"
                value="Dashboard"
              />
            </Link>
          </div>
          <div className="header-btns-right">
            <input
              type="submit"
              className="header-btns"
              name="sign out"
              value="Sign Out"
              onClick={logouthandle}
            />
          </div>
        </div>
      </div>
      <div className="bg-black h-screen flex flex-row text-white pt-12">
        <aside className="left-sidemenu">
          <div
            className={`left-side-menu-button ${
              currentStep === 1 ? "active" : ""
            }`}
            onClick={() => changeStep(1)}
          >
            <span>1</span>
            Company Info
          </div>
          <div
            className={`left-side-menu-button ${
              currentStep === 2 ? "active" : ""
            }`}
            onClick={() => changeStep(2)}
          >
            <span>2</span>
            Team Info
          </div>
          <div
            className={`left-side-menu-button ${
              currentStep === 3 ? "active" : ""
            }`}
            onClick={() => changeStep(3)}
          >
            <span>3</span>
            Grant Info
          </div>
        </aside>
        <section className="form">
          <div className="top">
            <h2 className="flex flex-col items-center pt-4">
              <input
                type="text"
                value={profileName}
                placeholder="Name Your Grant Proposal *"
                onChange={(e) => {
                  setProfileName(e.target.value);
                  console.log("this is prefilled form" + e.target.value);
                  localStorage.setItem("oldprofile", e.target.value);
                }}
                className="section-name-input"
                style={{ backgroundColor: "#2e0f68" }}
                autoFocus
                required
              />
            </h2>
          </div>
          
          <form
            onSubmit={handleSubmit}
            style={{ display: "flex", flexDirection: "column", gap: "1em", padding: '20px'}}
          >
            {renderstepFields(currentStep)}
          </form>

          {currentStep < totalSteps && (
            <div className="button" onClick={handleNext}>
              <label type="button"> Next </label>
            </div>
          )}
          {currentStep === totalSteps && (
            <div className="button" onClick={handleSubmit}>
              <label type="button"> Save and Start</label>
            </div>
          )}
        </section>
        <FileUploader
          from={"grantaide"}
          uid={uid}
          sendDataToParent={handleChildData}
        />
      </div>
    </div>
  );
}

// { message } needs to be passed to "ChatMessage", holding for testing

export default Main;
