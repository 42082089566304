import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { createUserWithEmailAndPassword, deleteUser } from "firebase/auth";
import { Link } from "react-router-dom";
import { auth, db } from "../../utils/firebase";
import { getDatabase, ref, set, child, get } from "firebase/database";
import { addDoc, collection, runTransaction } from "firebase/firestore";




//Main function logic
function Signup() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [name, setName] = useState("");

  const onSubmit = async (e) => {
    e.preventDefault();

    await createUserWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        try {
          await addDoc(collection(db, "UsersPersonalInfo"), {
            email: email,
            phone: phone,
            name: name,
            id: userCredential.user.uid,
          });
          navigate("/form");
        } catch (e) {
          await deleteUser(userCredential.user);
        }
      })
      .catch((error) => {
        console.log("Error:", error);
        console.log("code", error.code);
        if (error.code === "auth/email-already-in-use") {
          alert(
            "Email already exists.\n Try logging in or changing password if you forgot password."
          );
        }
      });

  };
  return (
    <div className="login-page">
      <div className="wrapper">
        <div className="title">Sign up</div>
        <form onSubmit={onSubmit}>
          <div className="field">
            <input
              type="name"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
            <label htmlFor="name">Name</label>
          </div>
          <div className="field">
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <label htmlFor="email">Email Address</label>
          </div>
          <div className="field">
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <label htmlFor="password">Password</label>
          </div>
          <div className="field">
            <input
              type="text"
              id="phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              required
            />
            <label htmlFor="phone">Phone</label>
          </div>
          <div className="field">
            <input type="submit" id="submit SignUp" value="Sign Up" />
          </div>
          <div className="terms">
            <label htmlFor="remember-me">
              By signing up, you agree to the{" "}
              <Link to="/terms" target="_blank">
                Terms of Service
              </Link>{" "}
              and{" "}
              <Link to="/privacy" target="_blank">
                Privacy Policy
              </Link>
            </label>
          </div>

          <div className="signup-link">
            Already Registered?{" "}
            <Link to="/login">
              <a href="#">Login </a>
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Signup;
