import React, { useState } from "react";
import {
  Container,
  Grid,
  Switch,
  Typography,
  Box,
  Button,
  List,
  ListItem,
  Card,
  CardContent,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip from '@mui/material/Tooltip';

const PricingPlans = () => {
  const [isMonthly, setIsMonthly] = useState(true);

  const handleSwitchChange = () => {
    setIsMonthly(!isMonthly);
  };

  const plans = [
    // {
    //   title: 'GrantAIde',
    //   price: 'Features',
    //   items: [
    //     'Browser Workstation',
    //     'Collaboration',
    //     'Text AI Integration',
    //     'Speech To Text Integration',
    //     'File Uploads',
    //     'Customer Support',
    //     '',
    //     'Coming soon:',
    //     'Automation to 3rd party apps',
    //     'AI Assistant',
    //     'Fine tuning',
    //     'A/B Testing'
    //   ]
    // },

    // TO UNDO PRICING CARDS Rene
    {
      title: "Basic",
      price: isMonthly ? "$29/month" : "$39/month",
      tag: "Starter",
      items: [
        "AI Grant Proposal Writer",
        "AI RFP Response Writer",
        "Unlimited Proposals",
        "Unlimited Proposal Templates",
        "15 AI Interactions / month",
        "10 Upload Documents / month",
        "Export Grants To PDF",
      ],
    },
    {
      title: "Pro",
      price: isMonthly ? "$59/month" : "$82/month",
      tag: "Most Popular",
      items: [
        "Grant Search Database",
        "3 Industry Report",
        "AI Grant Proposal Writer",
        "AI RFP Response Writer",
        "Unlimited Proposals",
        "Unlimited Proposal Templates",
        "Unlimited AI Interactions",
        "Unlimited Document Uploads",
        "Export Grants To PDF & DOCX",
      ],
    },
    {
      title: `Agency`,
      price: isMonthly ? "$179/month" : "$249/month",
      tag: "Unlimited Client Accounts",
      items: [
        "Unlimited Clients",
        "Grant Search Database",
        "Unlimited Industry Report",
        "AI Grant Proposal Writer",
        "AI RFP Response Writer",
        "Unlimited Proposals",
        "Unlimited Proposal Templates",
        "Unlimited AI Interactions",
        "Unlimited Document Uploads",
        "Export Grants To PDF & DOCX",
      ],
    },
  ];

  const navigate = useNavigate();

  return (
    <Container>
      <Box textAlign="center" mb={4} mt={2}>
        <Typography sx={{ color: "#fb4cff" }} variant="h4">
          Choose A Plan
        </Typography>
        <Box display="flex" justifyContent="center" alignItems="center" mb={4}>
          <Typography variant="h6">Monthly</Typography>
          <Switch
            checked={isMonthly}
            onChange={handleSwitchChange}
            sx={{
              "& .MuiSwitch-switchBase": {
                "&.Mui-checked": {
                  color: "#3c88f1",
                  "& + .MuiSwitch-track": {
                    backgroundColor: "#3c88f1",
                  },
                },
              },
              "& .MuiSwitch-track": {
                backgroundColor: "#3c88f1",
              },
            }}
          />
          <Typography variant="h6">Annually</Typography>
        </Box>
      </Box>

      <Grid container spacing={4} justifyContent="center">
        {plans.map((plan, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card
              sx={{
                height: "100%",
                width: "360px",
                padding: 2,
                display: "flex",
                flexDirection: "column",
                border: "5px solid #2355bf",
                borderRadius: "11px",
                boxShadow: "4px 4px 5px lightblue",
              }}
            >
              <CardContent sx={{ flexGrow: 1 }}>
                <Typography
                  variant="h4"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  gutterBottom
                  sx={{
                    fontWeight: "bold",
                    color: "#00068e",
                    fontFamily: "inherit",
                  }}
                >
                  {plan.title === 'Agency' ? (
                    <>
                      {plan.title}
                      <Tooltip 
                        placement="top" 
                        title={
                          <Box component="span" sx={{ fontSize: 14 }}>
                            Manage your first 4 Clients for FREE. Then $149 after.
                          </Box>
                        }
                      >
                        <InfoIcon sx={{ mr: 2}} fontSize="large" className="blackIcon ml-2"/>
                      </Tooltip>
                    </>
                  ) : (
                    plan.title
                  )}
                </Typography>
                <Typography
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  gutterBottom
                >
                  {plan.tag}
                </Typography>
                <Typography
                  variant="h5"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  gutterBottom
                  sx={{ color: "#00068e" }}
                >
                  {plan.price}
                </Typography>
                <Box textAlign="center" pb={3}>
                  <Button
                    id={`choose_plan_${plan.title}`}
                    href="signup"
                    variant="contained"
                    size="large"
                    sx={{
                      backgroundColor: "#3c88f1",
                      color: "#fff",
                      width: "100%",
                      height: "55px",
                    }}
                  >
                    Choose Plan
                  </Button>
                </Box>
                <List>
                  {plan.items.map((item, idx) => (
                    <ListItem key={idx} sx={{ color: "#7e00ff" }}>
                      {item}
                    </ListItem>
                  ))}
                </List>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default PricingPlans;
