import React, { useState } from 'react';
import './TermsOfService.css'; // Ensure this path points to the correct CSS file

function TermsOfService() {
    const [expandedSections, setExpandedSections] = useState({});

    const toggleSection = (section) => {
        setExpandedSections((prev) => ({
            ...prev,
            [section]: !prev[section],
        }));
    };

    return (
        <div className="terms-container">
            {/* <nav className="terms-nav">
                <Link to="/termsofservice">
                    <button className="active">Terms of Service</button>
                </Link>
                <Link to="/privacypolicy">
                    <button>Privacy Policy</button>
                </Link>
                <Link to="/dataprocessing">
                    <button>Data Processing Agreement</button>
                </Link>
            </nav> */}
            <h1 className="terms-heading">Terms of Service</h1>
            <div className="terms-section">
                <div
                    className="terms-subheading"
                    onClick={() => toggleSection('section1')}
                >
                     <span>Section 1: Introduction</span>
                    <span className="symbol">{expandedSections['section1'] ? "−" : "+"}</span>
                </div>
                {expandedSections['section1'] && (
                    <div className="terms-paragraph">
                        This is the introduction paragraph for the Terms of Service.
                    </div>
                )}
            </div>
            <div className="terms-section">
                <div
                    className="terms-subheading"
                    onClick={() => toggleSection('section2')}
                >
                    <span>Section 2: Introduction</span>
                    <span className="symbol">{expandedSections['section2'] ? "−" : "+"}</span>
                </div>
                {expandedSections['section2'] && (
                    <div className="terms-paragraph">
                        This is the user obligations paragraph.
                    </div>
                )}
            </div>
            {/* Add more sections as needed */}
        </div>
    );
}

export default TermsOfService;
